<template>
  <div>
    <div
      class="renewalBanner"
      v-for="(notification, index) of filteredRenewalNotifications"
      :key="index"
    >
      <brand-banner
        v-if="isBannerDisplayed(notification.id)"
        class="reminder"
        :closeable="false"
        @hide="hideBanner(notification.id)"
      >
        <p class="bannerParagrapgh bannerHeading">
          {{ getTitle(notification.message.additionalInfo.renewalAppStatus) }}
        </p>
        <p class="bannerParagrapgh">
          {{ getMessage(notification.message.additionalInfo.renewalAppStatus) }}
        </p>

        <p class="bannerParagrapgh">
          <brand-link
            class="link"
            :href="link(notification.message.additionalInfo.parentApplicationId)"
          >
            <div class="linkText">
              <div>{{ getLinkText(notification.message.additionalInfo.renewalAppStatus) }}</div>
              <svg-use class="arrow" name="arrow" width="6" height="10" />
            </div>
          </brand-link>
        </p>
      </brand-banner>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { renewalAppStatuses } from '@/constants/notification';
import BrandBanner from '@/components/BrandBanner';

export default {
  name: 'application-renewal-banner',
  components: { BrandBanner },
  props: {
    isPermanentlySticky: Boolean,
    isAlwaysDisplayed: Boolean,
  },
  data() {
    return { shouldHideIds: [], phoneNumber: this.$env.VUE_APP_CUSTOMER_SERVICE_PHONE };
  },
  computed: {
    ...mapGetters(['productType']),
    ...mapState('notification', ['acknowledgedNotifications']),
    ...mapGetters('notification', ['renewalNotifications']),
    filteredRenewalNotifications() {
      return this.renewalNotifications.filter(
        ({
          message: {
            additionalInfo: { renewalAppStatus },
          },
        }) => renewalAppStatus !== renewalAppStatuses.COMPLETED,
      );
    },
    isBannerDisplayed() {
      return id =>
        this.isAlwaysDisplayed ||
        !this.acknowledgedNotifications.includes(id) ||
        !this.shouldHideIds.includes(id);
    },
    closeable() {
      return sticky => !(this.isPermanentlySticky || sticky);
    },
    getTitle() {
      return status => this.$t(`applicationRenewalBanner.${this.productType}.title.${status}`);
    },
    getMessage() {
      return status =>
        this.$t(`applicationRenewalBanner.${this.productType}.message.${status}`, {
          phoneNumber: this.phoneNumber,
        });
    },
    getLinkText() {
      return status => this.$t(`applicationRenewalBanner.${this.productType}.linkText.${status}`);
    },
    link() {
      return parentApplicationId => {
        return `${this.$env.VUE_APP_APPLICATION_UI_LOGIN_LINK}?renewal=true&applicationId=${parentApplicationId}`;
      };
    },
  },
  methods: {
    ...mapActions('notification', ['closeBanner']),
    hideBanner(id) {
      this.shouldHideIds = [...this.shouldHideIds, id];
      this.closeBanner(id);
    },
  },
  created() {
    this.shouldHideIds = this.filteredRenewalNotifications
      .filter(({ acknowledged }) => acknowledged)
      .map(({ id }) => id);
  },
};
</script>
<style lang="sass" scoped>
.renewalBanner
  &:not(:last-of-type)
    margin-bottom: $glass-spacing-105

.reminder
  background-color: $color-system-validation-success !important
  color: $color-white-four !important

.bannerHeading
  font-weight: bold

.bannerParagrapgh
  &:not(:last-of-type)
    margin-bottom: baseunit()

.arrow
  transform: rotate(180deg)

.linkText
  display: flex
  align-items: baseline
  gap: baseunit()

.link
  color: $glass-color-system-neutral-white
</style>
