<template>
  <div class="baseLayoutContainer">
    <header>
      <application-header />
      <banners />
    </header>
    <div class="baseContent">
      <article class="mainContent">
        <slot />
      </article>
    </div>
    <div id="footer">
      <brand-footer :partnerPhone="partnerPhone" :partnerEmail="partnerEmail">
        <template #partner-logo>
          <svg-use class="partnerLogo" name="component _ logo _ left _ dynamic bank" />
        </template>
      </brand-footer>
    </div>
  </div>
</template>
<script>
import ApplicationHeader from '@/components/ApplicationHeader';
import BrandFooter from '@/components/BrandFooter';
import Banners from '@/views/Banners';

export default {
  name: 'base-layout',
  components: {
    ApplicationHeader,
    BrandFooter,
    Banners,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const supportedBrowsers = this.$env.VUE_APP_SUPPORTED_BROWSERS.split(',');

    return {
      supportedBrowsers,
      partnerPhone: this.$env.VUE_APP_PARTNER_PHONE,
      partnerEmail: this.$env.VUE_APP_PARTNER_EMAIL,
    };
  },
};
</script>
<style lang="sass" scoped>
.baseLayoutContainer
  width: 100%
  display: flex
  flex-direction: column
  position: relative
  min-height: 100vh

  header
    flex-shrink: 0
    position: sticky
    top: 0
    z-index: 1000

  .baseContent
    flex: 1 0 auto
    width: 100%
    display: flex
    justify-content: space-between
    padding: $content-vertical-padding-mobile $content-horizontal-padding-mobile baseunit(23.5)
    flex-direction: row
    max-width: $base-content-max-width
    position:relative
    margin: 0 auto baseunit(7)
    +mq($ankylo)
      padding: $content-vertical-padding $content-horizontal-padding baseunit(23.5)
      position:relative

    .mainContent
      min-height: baseunit(40)
      width: $main-content-width
      position:relative

.partnerLogo
  height: baseunit(4.8)
  width: baseunit(16.2)

#footer
  float: left
  right: 0
  bottom: 0
  left: 0
  z-index: 0
  margin-top: 0
  position: absolute
</style>
