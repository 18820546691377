export const notificationSubjects = {
  PAST_DUE: 'PAST_DUE',
  ADR: 'ADR',
  PAYMENT_SUBMITTED: 'PAYMENT_SUBMITTED',
  HOLIDAY: 'HOLIDAY',
  BANK_ACCOUNT_CHANGE: 'BANK_ACCOUNT_CHANGE',
  WELCOME: 'WELCOME',
  PAID_OFF: 'PAID_OFF',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  DOC_SUBMIT_SUCCESS: 'DOC_SUBMIT_SUCCESS',
  SUBMITTED_DOCUMENTS: 'SUBMITTED_DOCUMENTS',
  MANAGE_ACCOUNT_ATTEMPT: 'MANAGE_ACCOUNT_ATTEMPT',
  ON_HOLD: 'ON_HOLD',
  PAYMENT_FAIL_ON_HOLD: 'PAYMENT_FAIL_ON_HOLD',
  WRITE_OFF: 'WRITE_OFF',
  POST_FUNDING: 'POST_FUNDING',
  REVOKE_ELECTRONIC_CONSENT: 'REVOKE_ELECTRONIC_CONSENT',
  APPLICATION_RENEWAL: 'APPLICATION_RENEWAL',
  CHARGED_OFF: 'CHARGED_OFF',
  CANCELLED: 'CANCELLED',
};

export const postFundingStatuses = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  PENDING: 'PENDING',
};

export const renewalAppStatuses = {
  NOT_SUBMIT: 'NOT_SUBMIT',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
};
