<template>
  <div class="termLoanItem">
    <div class="body">
      <div class="column">
        <brand-link class="title" :to="toLoan">
          {{ $t(`loanName.${productType}`, { loanNumber }) }}
        </brand-link>
        <div class="row">
          <paid-off-label :percentPaidDown="percentPaidDown" />
          <label>{{ loanAmountLabel }}</label>
        </div>
      </div>
      <div class="column">
        <div class="value">
          {{ outstandingBalance | money }}
        </div>
        <label>{{ $t('loanInformation.outstandingBalance') }}</label>
      </div>
      <div class="column">
        <div class="value">
          {{ nextInstallmentAmount | money }}
        </div>
        <label>{{
          $t('loanInformation.scheduledPaymentAmountLabel', {
            scheduled: $t(`loanInformation.scheduled.${scheduled}`),
          })
        }}</label>
      </div>
      <div class="column">
        <div class="value">
          {{ remainingPayments }}
        </div>
        <label>{{ $t('loanInformation.remainingPayments') }}</label>
      </div>
      <div class="column">
        <brand-button class="payment" outline :disabled="isPaidOff" :to="toOneTimePayments">
          <svg-use name="Icons_Financial_Payment" width="16" height="16" />
          {{ $t('loanInformation.paymentButtonLabel') }}
        </brand-button>
      </div>
      <div class="column" v-if="showStandardPaymentScheduleDropdown">
        <dropdown
          class="dropdown"
          svgName="component _ utility _ system _ navigation-overflow horizontal"
        >
          <ul class="dropdownList">
            <li class="dropdownItem">
              <brand-link v-if="!isPaidOff" :to="toPaymentSchedule">
                {{ $t('loanInformation.paymentSchedule') }}
              </brand-link>
              <p v-else>{{ $t('loanInformation.emptyDropdown') }}</p>
            </li>
          </ul>
        </dropdown>
      </div>
    </div>
    <div class="paymentScheduled" v-if="loanStatus">
      <svg-use
        class="icon"
        name="component _ utility _ operations _ pass fail"
        width="18"
        height="18"
      />
      {{
        $t(`loanInformation.recurringPaymentStatus.${loanStatus}`, {
          scheduled: scheduledLabel,
          scheduleDate,
        })
      }}
    </div>
  </div>
</template>

<script>
import PaidOffLabel from '@/components/PaidOffLabel';
import Dropdown from '@/components/Dropdown';
import dateUtils from '@/utils/dateUtils';

export default {
  name: 'term-loan-item',
  components: {
    PaidOffLabel,
    Dropdown,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showStandardPaymentScheduleDropdown: this.$env.VUE_APP_SHOW_PAYMENT_SCHEDULE_DROPDOWN,
    };
  },
  computed: {
    productType() {
      return this.loan.productType;
    },
    loanNumber() {
      return this.loan.externalLoanId;
    },
    percentPaidDown() {
      return this.loan.percentPaidDown;
    },
    outstandingBalance() {
      return this.loan.outstandingBalance || 0;
    },
    nextInstallmentAmount() {
      return this.loan.nextInstallmentAmount;
    },
    scheduled() {
      return this.loan.periodUnit;
    },
    scheduleDate() {
      const date = new Date(this.loan.scheduleDate);
      const format = 'MMMM dd, yyyy';
      return this.formatDate(date, format);
    },
    remainingPayments() {
      return this.loan.remainingInstallments;
    },
    loanStatus() {
      return this.loan.loanStatus;
    },
    isPaidOff() {
      return this.percentPaidDown === 100;
    },
    loanAmountLabel() {
      return `${this.moneyFilter(this.loan.amount)} ${this.productLabel}`;
    },
    productLabel() {
      return this.$t(`products.${this.productType}`);
    },
    scheduledLabel() {
      return this.$t(`loanInformation.scheduled.${this.scheduled}`).toLowerCase();
    },
    toLoan() {
      return { path: `loans/${this.loanNumber}` };
    },
    toOneTimePayments() {
      return { path: `loans/${this.loanNumber}/one_time_payment` };
    },
    toPaymentSchedule() {
      return { path: `loans/${this.loanNumber}/payment_schedule` };
    },
  },
  methods: {
    formatDate(date, format) {
      const locale = this.$env.VUE_APP_I18N_LOCALE;
      const day = date.toLocaleDateString(locale, { weekday: 'long' });
      const formattedDate = dateUtils.formatDate(date, format);
      return `${day}, ${formattedDate}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.termLoanItem
  width: 100%
  height: 100%

  .body
    display: flex
    align-items: center
    justify-content: space-around
    margin: baseunit(1.2) baseunit(2.4)

    .title
      font-family: $fonts-public-sans-bold
      +font-size(16,28)
      color: $glass-color-system-neutral-grey-09

    label
      +font-size(12, 16)
      color: $color-system-neutral-gray-5
      font-weight: 600

    .value
      +font-size(12, 18)
      font-family: $fonts-public-sans-regular
      text-overflow: ellipsis
      overflow: hidden

    .row
      display: flex
      align-items: center


    .payment
      display: flex
      align-items: center
      +font-size(14, 16)

      svg
        margin-right: baseunit(0.3)

    .dropdown
      display: flex
      flex-direction: row

      .dropdownList
        margin: 0
        padding: 0
        +font-size(14, 16)
        color: $color-system-neutral-gray-5
        font-weight: 500
        font-family: $fonts-public-sans-regular

        .dropdownItem
          list-style-type: none

          &+.dropdownItem
            margin-top: baseunit(0.75)

    .column
      padding: baseunit(1.6) 0
      width: max-content
      text-align: left

      &+.column
        margin-left: baseunit(3)

  .paymentScheduled
    display: flex
    border-top: 1px solid $glass-color-system-neutral-grey-06
    padding: baseunit(1.2) baseunit(2.4)
    font-family: $fonts-public-sans-regular
    +font-size(12, 20)
    color: $color-system-neutral-gray-5

    .icon
      margin-right: baseunit(1)
</style>
