import api from '@/modules/api-service';
import adr from '@/store/modules/additionalDataRequest';
import bankAccount from '@/store/modules/bankAccount';
import bankHolidays from '@/store/modules/bankHolidays';
import business from '@/store/modules/business';
import customer from '@/store/modules/customer';
import document from '@/store/modules/document';
import file from '@/store/modules/file';
import holdHistory from '@/store/modules/holdHistory';
import loan from '@/store/modules/loan';
import loanBalance from '@/store/modules/loanBalance';
import notification from '@/store/modules/notification';
import oneTimePayment from '@/store/modules/oneTimePayment';
import paymentSchedule from '@/store/modules/paymentSchedule';
import template from '@/store/modules/template';
import transactionsHistory from '@/store/modules/transactionsHistory';

import {
  accountStateResolver,
  accountStates,
  loanStateResolver,
  loanStates,
} from '@/modules/stateResolver';

export default {
  namespaced: true,
  state: {
    currentLoanNumber: '',
    loans: [],
  },
  getters: {
    currentLoan: ({ loans, currentLoanNumber }) => {
      return loans.find(({ externalLoanId }) => currentLoanNumber === externalLoanId) || loans[0];
    },
    productType: (state, { currentLoan }) => {
      return currentLoan.productType;
    },
    loanId: (state, { currentLoan }) => {
      return currentLoan.id || null;
    },
    currentLoanNumber: (state, { currentLoan }) => {
      return currentLoan.externalLoanId || null;
    },
    isEmptyLoans: ({ loans }) => loans.length === 0,
    isOneLoan: ({ loans }) => loans.length === 1,
    loanState: (state, getters, rootState, rootGetters) =>
      loanStateResolver(
        state.loan,
        state.loanBalance,
        rootGetters['holdHistory/isBankAccountHold'],
      ),
    getLoanStateByLoanId: ({ loans }, getters, rootState, rootGetters) => loanId => {
      const { autoDebitMode, loanStatus, outstandingBalance, subState } = loans.find(
        ({ id }) => id === loanId,
      );
      return loanStateResolver(
        { autoDebitMode },
        { loanStatus, outstandingBalance, subState },
        rootGetters['holdHistory/isBankAccountHold'],
      );
    },
    accountState: (state, getters, rootState, rootGetters) =>
      accountStateResolver(
        state.loan,
        state.loanBalance,
        rootGetters['holdHistory/isBankAccountHold'],
      ),
    isPastDue: (_, { accountState }) =>
      [accountStates.STOPPED, accountStates.LOCKED].includes(accountState),
    isAccountLocked: (_, { accountState }) => accountState === accountStates.LOCKED,
    isOneTimePaymentsAllowed: (_, { loanState }) =>
      [loanStates.ACTIVE, loanStates.STOPPED, loanStates.LOCKED].includes(loanState),
    isPaymentScheduleAllowed: (_, { loanState }) =>
      [
        loanStates.ACTIVE,
        loanStates.ACTIVE_IN_ARREARS,
        loanStates.APPROVED,
        loanStates.LOCKED,
      ].includes(loanState),
  },
  mutations: {
    updateLoans(state, loans) {
      state.loans = loans;
      state.currentLoanNumber = loans[0].externalLoanId;
    },
    updateCurrentLoanNumber(state, loanNumber) {
      state.currentLoanNumber = loanNumber;
    },
  },
  actions: {
    async getAllLoans({ commit }) {
      const { data: loanData } = await api.getAllLoans();

      if (!(Array.isArray(loanData) && loanData.length > 0)) {
        throw new Error('There is no loans for this customer');
      }
      const loans = loanData.map(({ loan: data, productType }) => ({
        ...data,
        productType,
      }));

      const dashboardData = await Promise.all(loans.map(({ id }) => api.getDashboardData(id)));

      const enrichedLoans = dashboardData.map(
        ({
          data: {
            loan: { id },
            balance: {
              loanStatus,
              outstandingBalance,
              subState,
              nextInstallmentAmount,
              percentPaidDown,
              remainingInstallments,
            },
            paymentSchedule: { payments },
          },
        }) => {
          return {
            ...loans.find(({ id: loanId }) => loanId === id),
            scheduleDate: payments.find(({ status }) => status === 'PENDING')?.date,
            loanStatus,
            outstandingBalance,
            subState,
            nextInstallmentAmount,
            percentPaidDown,
            remainingInstallments,
          };
        },
      );

      commit('updateLoans', enrichedLoans);
    },
    async getDashboardData({ commit, getters, rootGetters }) {
      const { data: loanData } = await api.getAllLoans();

      if (!(Array.isArray(loanData) && loanData.length > 0)) {
        throw new Error('There is no loans for this customer');
      }
      const isActiveLoanAvailable = loanData.find(({ closed }) => closed === false);
      return api.getDashboardData(getters.loanId).then(response => {
        if (response.data) {
          const mapping = {
            loan: 'loan/updateLoanInfo',
            customer: 'customer/updateCustomer',
            business: 'business/updateBusiness',
            balance: 'loanBalance/updateLoanBalance',
            notifications: 'notification/updateNotifications',
            activities: 'transactionsHistory/updateTransactionsHistory',
            paymentSchedule: 'paymentSchedule/updatePaymentSchedule',
            holdHistory: 'holdHistory/updateHoldHistory',
          };

          Object.keys(mapping).forEach(key => {
            if (
              key === 'notifications' &&
              response.data[key] &&
              isActiveLoanAvailable &&
              !isActiveLoanAvailable.closed
            ) {
              commit(
                mapping[key],
                response.data.notifications.filter(item => !(item.subject === 'PAID_OFF')),
              );
            } else if (response.data[key]) {
              commit(mapping[key], response.data[key]);
            }
          });

          if (rootGetters['holdHistory/isBankAccountHold']) {
            commit('notification/createBankAccountChangeNotification', {}, { root: true });
          }
        }
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    adr,
    bankAccount,
    bankHolidays,
    business,
    customer,
    document,
    file,
    holdHistory,
    loan,
    loanBalance,
    notification,
    oneTimePayment,
    paymentSchedule,
    template,
    transactionsHistory,
  },
};
