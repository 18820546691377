<template>
  <div>
    <div v-if="isSubmitted || !initialized" class="paymentBox" key="spinner">
      <spinner />
    </div>
    <div v-else class="paymentBox" key="paymentBox">
      <div class="paymentContent">
        <h1 class="header">{{ $t('payment.details.makePaymentCaption') }}</h1>
        <div class="confirmOneTimePaymentContainer" v-if="inConfirmStage">
          <confirm-one-time-payment />
        </div>
        <one-time-payment-details v-else key="in-edit-stage">
          <template #detailsHeader>
            <h2>
              {{ $t('loanNavigation.one_time_payment') }}
            </h2>
          </template>
          <template #amountInput>
            <payment-amount
              :maxAmount="maxAmount"
              :errorOccurred="errorOccurred"
              ref="amountPayment"
              @updateValue="updateAmount"
            />
          </template>
          <template #dateInput>
            <one-time-payment-date
              class="paymentDate"
              ref="paymentDate"
              :isPaidInFull="true"
              @updateValue="updateDate"
            />
          </template>
        </one-time-payment-details>

        <div class="actionButtons">
          <one-time-payment-actions
            :cancelButtonLabel="cancelButtonLabel"
            :submitButtonLabel="submitButtonLabel"
            @submit="handleSubmit"
            @cancel="handleCancel"
          ></one-time-payment-actions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { DateTime } from 'luxon';
import ruleTypesMixin from '@/mixins/ruleTypesMixin';
import oneTimePaymentGuard from '@/mixins/oneTimePaymentGuard';
import getDataBeforeRenderMixin from '@/mixins/getDataBeforeRenderMixin';
import { NETWORK, OTP_BEYOND_THRESHOLD } from '@/validation/error-types';
import OneTimePaymentDetails from '@/components/OneTimePayment/Details';
import OneTimePaymentActions from '@/components/OneTimePayment/ActionButtons';
import ConfirmOneTimePayment from '@/components/OneTimePayment/ConfirmOneTimePayment';
import PaymentAmount from '@/components/OneTimePayment/PaymentAmount';
import OneTimePaymentDate from '@/components/OneTimePayment/PaymentDate';
import validationMixin from '@/mixins/validationMixin';
import { INVALID_DATA } from '@/modules/api-error/types';

const BASE = 'base';

export default {
  name: 'one-time-payment',
  components: {
    OneTimePaymentDetails,
    OneTimePaymentActions,
    ConfirmOneTimePayment,
    PaymentAmount,
    OneTimePaymentDate,
  },
  mixins: [oneTimePaymentGuard, ruleTypesMixin, getDataBeforeRenderMixin, validationMixin],
  data() {
    return {
      inConfirmStage: false,
      isSubmitted: false,
      errorOccurred: false,
      partnerTimezone: this.$env.VUE_APP_PARTNER_TIMEZONE,
      partnerPhone: this.$env.VUE_APP_PARTNER_PHONE,
      maxAmount: Number.MAX_SAFE_INTEGER,
    };
  },
  props: {
    preFillAmount: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    ...mapState('loanBalance', ['outstandingBalance', 'payoffDate']),
    ...mapState('bankAccount', ['accountNumber']),
    ...mapState('oneTimePayment', { paymentAmount: 'amount', paymentDate: 'scheduleDate' }),
    ...mapState('bankHolidays', ['holidays']),
    cancelButtonLabel() {
      return this.inConfirmStage
        ? this.$t('payment.button.backButtonCaption')
        : this.$t('payment.button.cancelButtonCaption');
    },
    submitButtonLabel() {
      return this.inConfirmStage
        ? this.$t('payment.button.confirmationButtonCaption')
        : this.$t('payment.button.makePaymentButtonCaption');
    },
  },
  methods: {
    ...mapMutations('oneTimePayment', ['setAmount', 'setDate']),
    ...mapActions('oneTimePayment', ['makeOneTimePayment']),
    ...mapActions('bankHolidays', ['getHolidays']),
    isFormValid() {
      const isAmountValid = this.$refs?.amountPayment?.isAmountValid || false;
      const isDateValid = this.$refs?.paymentDate?.isDateValid || false;

      return isAmountValid && isDateValid;
    },
    updateAmount(value) {
      this.setAmount(value);
    },
    updateDate(value) {
      if (!value || typeof value !== 'object' || value.constructor.name !== 'Date') {
        return;
      }

      const year = value.getFullYear();
      const month = `0${value.getMonth() + 1}`.slice(-2);
      const day = `0${value.getDate()}`.slice(-2);

      this.setDate(`${year}-${month}-${day}`);
    },
    submitPayment() {
      const params = { amount: this.paymentAmount, date: this.paymentDate };

      this.isSubmitted = true;
      this.makeOneTimePayment()
        .then(() => {
          this.$router.replace({
            name: 'paymentSubmitted',
            params,
          });
        })
        .catch(error => {
          if (error.type === INVALID_DATA && error.data?.type === OTP_BEYOND_THRESHOLD) {
            this.errorOccurred = true;
            this.maxAmount = error.data.maxAmount;
          } else {
            this.errors.push({
              field: BASE,
              errorMessage: NETWORK,
            });
          }
        })
        .finally(() => {
          this.inConfirmStage = false;
          this.isSubmitted = false;
        });
    },
    handleSubmit() {
      if (this.inConfirmStage) {
        this.submitPayment();
      } else {
        this.$refs.amountPayment.validation();
        this.$refs.paymentDate.validation();
        this.inConfirmStage = this.isFormValid();
      }
    },
    handleCancel() {
      if (this.inConfirmStage) {
        this.inConfirmStage = false;
      } else {
        this.$router.back();
      }
    },
    async getDataBeforeRender() {
      await this.getHolidays(this.payoffDate);

      if (this.preFillAmount) this.updateAmount(this.preFillAmount);
      this.updateDate(this.getNextAvailableDate());
    },
    isHoliday(checkDate) {
      return this.holidays.some(({ date }) => {
        return date === checkDate.toISODate();
      });
    },
    getNextAvailableDate() {
      let date = DateTime.local();
      while (this.isHoliday(date)) {
        date = date.plus({ day: 1 });
      }
      return date
        .setZone(this.$env.VUE_APP_PARTNER_TIMEZONE)
        .startOf('day')
        .setZone('local', { keepLocalTime: true })
        .toJSDate();
    },
  },
  beforeRouteLeave(_, __, next) {
    this.setAmount('');
    this.setDate('');
    next();
  },
};
</script>

<style lang="sass" scoped>
@import '@/components/DateInput/_variables'

.validationError
  margin-top: baseunit()

.confirmOneTimePaymentContainer
  margin-top: baseunit(1.5)

.detailsBox,
.header
  margin: 0

.header
  color: $glass-color-system-pop-grey-dark
  +font-size(28, 32)

.actionButtons
  margin-top: baseunit()

.paymentBox
  margin-top: baseunit(2)
  padding: baseunit(3) baseunit(4.5)
  width: 100%

  .paymentContent
    margin: auto

    .brandButton
      width: baseunit(18)
      height: baseunit(5)
      line-height: baseunit(4.8)
      margin-left: baseunit(1)

::v-deep .calendar
  margin: baseunit(0.2) 0 baseunit(2)

.paymentDate
  ::v-deep .inputContainer
    &.disabled
      background-color: $color-porcelain
      border: 1px solid $color-light-gray

    input
      color: $color-oslo-gray
</style>
