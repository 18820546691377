<template>
  <centred-spinner v-if="!initialized" key="spinner" />
  <div class="documents" v-else key="documents">
    <heading level="2">{{ $t('documents.documentsBlock.heading') }}</heading>
    <application-renewal-banner
      class="applicationRenewalBanner"
      isPermanentlySticky
      isAlwaysDisplayed
    />
    <loan-document
      v-if="isOneLoan"
      :loanId="loanId"
      :loanNumber="currentLoanNumber"
    ></loan-document>

    <span class="loanList" v-else>
      <collapsible-block
        v-for="{ id, externalLoanId: loanNumber, productType, percentPaidDown } in loans"
        :key="id"
        :id="id"
        :checked="isNotPaidOff(percentPaidDown)"
      >
        <template #title>
          <svg-use class="titleIcon" name="component _ utility _ operations _ document" />
          <div class="column">
            <div class="title">{{ $t(`loanName.${productType}`, { loanNumber }) }}</div>
            <div class="row">
              <paid-off-label :percentPaidDown="percentPaidDown" />
              <div class="number">{{ $t('loanIdentifier.loanNumber', { loanNumber }) }}</div>
            </div>
          </div>
        </template>
        <template #content>
          <loan-document :loanId="id" :loanNumber="loanNumber"></loan-document>
        </template>
      </collapsible-block>
    </span>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CentredSpinner from '@/components/CentredSpinner';
import CollapsibleBlock from '@/components/CollapsibleBlock';
import LoanDocument from '@/components/LoanDocument';
import PaidOffLabel from '@/components/PaidOffLabel';
import getDataBeforeRenderMixin from '@/mixins/getDataBeforeRenderMixin';
import ApplicationRenewalBanner from '@/views/Banners/ApplicationRenewalBanner';

export default {
  name: 'documents',
  components: {
    CentredSpinner,
    CollapsibleBlock,
    LoanDocument,
    PaidOffLabel,
    ApplicationRenewalBanner,
  },
  mixins: [getDataBeforeRenderMixin],
  computed: {
    ...mapState(['loans']),
    ...mapState('adr', ['adrs']),
    ...mapState('document', ['documents']),
    ...mapGetters(['isOneLoan', 'loanId', 'currentLoanNumber']),
    isNotPaidOff() {
      return percentPaidDown => percentPaidDown !== 100;
    },
  },
  methods: {
    ...mapActions('adr', ['getAdr']),
    ...mapActions('document', ['getDocuments']),
    ...mapActions('bankAccount', ['getBankAccount']),
    ...mapActions('template', ['getTemplates']),
    ...mapActions('file', ['getUploadedFiles', 'initStorage', 'cleanupValues']),
    async getDataBeforeRender() {
      await this.getAdr();
      await this.getDocuments();
      await this.getBankAccount();
      await this.getTemplates(this.adrs);
      await this.getUploadedFiles({ documents: this.documents, adrs: this.adrs });

      this.initStorage();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.cleanupValues();
    next();
  },
};
</script>
<style lang="sass" scoped>
.applicationRenewalBanner
  margin-bottom: $glass-spacing-105

.documents
  h2
    margin-bottom: $glass-spacing-2

  .loanList > * + *
    margin-top: $glass-spacing-2

.column
  display: flex
  flex-direction: column

.row
  display: flex
  align-items: center

.number
  color: $color-system-neutral-gray-5
  +font-size(14,20)
  font-family: $fonts-public-sans-regular

.title
  font-family: $fonts-public-sans-bold
  +font-size(20,28)
  color: $glass-color-system-neutral-grey-09

.titleIcon
  width: baseunit(2.4)
  height: baseunit(2.4)
  margin-right: $glass-spacing-1
  fill: $color-system-neutral-gray-5
</style>
