import api from '@/modules/api-service';

export default {
  namespaced: true,
  state: {
    amount: '',
    scheduleDate: '',
  },
  getters: {},
  actions: {
    makeOneTimePayment({ state, rootGetters, commit, dispatch }) {
      return api
        .addPayment(rootGetters.loanId, {
          amount: state.amount,
          scheduleDate: state.scheduleDate,
        })
        .then(() => {
          dispatch('getDashboardData', {}, { root: true });

          commit('setAmount', '');
          commit('setDate', '');
          setTimeout(() => {
            commit('notification/createPaymentSubmittedNotification', {}, { root: true });
          }, 500);
        });
    },
  },
  mutations: {
    setAmount(state, value) {
      if (typeof value !== 'undefined') {
        state.amount = value;
      }
    },
    setDate(state, value) {
      if (typeof value !== 'undefined') {
        state.scheduleDate = value;
      }
    },
  },
};
