<template>
  <div class="transactionHistory" v-if="showTransactionHistory">
    <div class="place">
      <div v-if="isLoanActivities">
        <div class="header">
          <div class="name">
            {{ $t('transactionHistory.header') }}
          </div>
          <brand-link
            @click="getLoanTransactions"
            class="downloadLink"
            v-if="showDownloadHistoryLink"
          >
            <svg-use class="downloadImage" name="component _ utility _ system _ action-download" />
            {{ $t('transactionHistory.downloadLink') }}
          </brand-link>
        </div>
        <div class="filters">
          <input-text
            id="search-by-type-or-status"
            class="inputSearch"
            v-model="searchValue"
            :placeholder="$t('transactionHistory.searchPlaceholder')"
            :showErrorSuffix="false"
          >
            <svg-use slot="prefix" name="component _ utility _ system _ navigation-search" />
          </input-text>
          <div class="timeFilter">
            <div class="label">{{ $t('transactionHistory.showingLabel') }}</div>
            <input-select
              id="show-by-time"
              :value="timePeriod"
              @change="timePeriod = $event"
              :options="timePeriods"
            >
            </input-select>
          </div>
        </div>
        <transaction-history-table :rows="filteredHistory" />
        <div v-if="filteredHistory.length === 0" class="noResults">
          {{ $t('transactionHistory.noResultsMessage') }}
        </div>
      </div>
      <div v-else>
        <div class="header">
          <div class="name">
            {{ $t('transactionHistory.header') }}
          </div>
        </div>
        <div class="noLoan">
          {{ $t('transactionHistory.noLoan') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import TransactionHistoryTable from '@/components/TransactionHistoryTable';
import dateUtils from '@/utils/dateUtils';
import { LAST_30_DAYS, LAST_90_DAYS, LAST_YEAR, getDateByPeriod } from '@/modules/last-periods';

const timePeriods = [LAST_30_DAYS, LAST_90_DAYS, LAST_YEAR];

export default {
  name: 'transaction-history',
  components: {
    TransactionHistoryTable,
  },
  data() {
    return {
      timePeriod: LAST_30_DAYS,
      today: new Date(),
      searchValue: '',
      showTransactionHistory: this.$env.VUE_APP_SHOW_TRANSACTION_HISTORY,
      showDownloadHistoryLink: this.$env.VUE_APP_SHOW_DOWNLOAD_TRANSACTION_HISTORY_LINK,
    };
  },
  computed: {
    ...mapGetters('transactionsHistory', {
      loanActivities: 'getLoanActivityData',
    }),
    isLoanActivities() {
      return Array.isArray(this.loanActivities) && this.loanActivities.length > 0;
    },
    filteredHistory() {
      if (this.searchValue) {
        const cleanSearchValue = this.searchValue.trim().toLowerCase();

        return this.historyFilteredByTime.filter(({ statusTxt, typeTxt }) => {
          let byStatus = false;
          let byType = false;
          if (statusTxt) {
            byStatus = statusTxt.toLowerCase().includes(cleanSearchValue);
          }
          if (typeTxt) {
            byType = typeTxt.toLowerCase().includes(cleanSearchValue);
          }

          return byStatus || byType;
        });
      }
      return this.historyFilteredByTime;
    },
    historyFilteredByTime() {
      const fromDate = getDateByPeriod(this.today, this.timePeriod);

      return this.lastTransactions(fromDate);
    },
    timePeriods() {
      return timePeriods.map(type => ({
        key: type,
        value: this.$t(`timePeriods.${type}`),
      }));
    },
  },
  methods: {
    ...mapActions('transactionsHistory', ['getLoanTransactions']),
    lastTransactions(fromDate) {
      return this.loanActivities.filter(({ date }) => {
        const convertedDate = dateUtils.convertStringToDate(date);
        return this.today >= convertedDate && convertedDate >= fromDate;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.transactionHistory
  width: 100%
  height: 100%
  border-radius: 4px
  border: 1px solid $glass-color-system-neutral-grey-04
  background-color: $glass-color-system-neutral-white
  font-family: $fonts-public-sans-regular

  .place
    padding: baseunit(2.4)

    .header
      display: flex
      line-height: 1.47
      justify-content: space-between

      .name
        font-family: $fonts-public-sans-bold
        display: flex
        +font-size(20, 28)
        color: $glass-color-system-neutral-grey-09

      .downloadLink
        display: flex
        +font-size(14,16)
        padding-top: baseunit(0.5)
        width: baseunit(12.6)

        svg
          color: $brand-link-partner-color
          margin-right: baseunit(.4)
          width: baseunit(1.6)
          height: baseunit(1.6)

    .noLoan
      padding: baseunit(1.7) baseunit(12.7) baseunit(1.5)
      +font-size(15)
      line-height: 1.47
      text-align: center

.filters
  display: flex
  justify-content: space-between
  margin-top: baseunit(2.8)

  .inputSearch
    width: 100%
    max-width: baseunit(42)


    ::v-deep .inputContainer
      font-family: $fonts-public-sans-regular
      color: $glass-color-system-neutral-grey-08
      padding: baseunit(0.8) baseunit(1.2) baseunit(0.8) baseunit(3.2)

.timeFilter
  display: flex
  align-items: center

  .label
    +font-size(16,20)
    color: $glass-color-system-neutral-grey-09
    margin-right: baseunit(0.8)

  .inputSelect
    min-width: baseunit(21)

.noResults
  font-family: $fonts-public-sans-regular
  color: $glass-color-system-neutral-grey-09
  +font-size(16,20)
  text-align: center
  padding: baseunit(2) 0
</style>
