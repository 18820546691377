<template>
  <div class="simpleBanner">
    <div
      :class="{
        mintBannerContent: subject == DOC_SUBMIT_SUCCESS,
        content: subject != DOC_SUBMIT_SUCCESS,
      }"
    >
      <slot>
        {{ message }}
      </slot>
      <div v-if="closeable" @click.prevent="closeBanner(id)" class="close">
        <svg-use name="cross" />
      </div>
    </div>
    <div v-if="subject == 'PAST_DUE'" class="extraPayment">
      <brand-button
        class="extraPaymentBtn"
        :to="{ name: 'oneTimePayment', params: { loanNumber: currentLoanNumber } }"
      >
        {{ $t('makeExtraPaymentBtnLabel') }}
      </brand-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'simple-banner',
  data() {
    return {
      DOC_SUBMIT_SUCCESS: 'DOC_SUBMIT_SUCCESS',
    };
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    closeable: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentLoanNumber']),
  },
  methods: {
    ...mapActions('notification', ['closeBanner']),
  },
};
</script>

<style lang="sass" scoped>
.simpleBanner
  background-color: $color-arapawa

  &.alert
    background-color: $color-fire
  &.success
    background-color: $color-secondary-accent-2
  .mintBannerContent
    +font-size(16)
    width: $content-width - 2 * $content-horizontal-padding
    min-height: baseunit(6.4)
    padding: baseunit(1.4) 0
    font-family: $font-family-galano-grotesque
    line-height: 2.19
    color: $color-gray
    margin: 0 auto
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $glass-color-system-neutral-white

    .close
      cursor: pointer

      .svguse
        width: baseunit(1.4)
        height: baseunit(1.4)
        color: $color-gray
        stroke: $color-gray
        stroke-width: 2

  .content
    +font-size(16)
    width: $content-width - 2 * $content-horizontal-padding
    min-height: baseunit(6.4)
    padding: baseunit(1.4) 0
    font-family: $font-family-galano-grotesque
    line-height: 2.19
    color: $glass-color-system-neutral-white
    margin: 0 auto
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $glass-color-system-neutral-white

    .close
      cursor: pointer

      .svguse
        width: baseunit(1.4)
        height: baseunit(1.4)
        color: $glass-color-system-neutral-white
        stroke: $glass-color-system-neutral-white
        stroke-width: 2

  &:last-child
    .content
      border-bottom-style: none

.extraPayment
  margin: 0 auto
  width: 61.5rem
  padding-bottom: baseunit(1.4)
  font-family: $font-family-galano-grotesque

.extraPaymentBtn
   border: 1px solid $glass-color-system-neutral-white
   background: transparent
</style>
