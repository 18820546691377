<template>
  <div class="loanInformation">
    <loan-identifier />
    <div class="buttons">
      <brand-button :disabled="!isOneTimePaymentsAllowed" :to="{ name: 'oneTimePayment' }">
        {{ $t('loanInformation.paymentButtonLabel') }}
      </brand-button>
      <brand-button
        v-if="isPaymentScheduleAllowed && showPaymentScheduleButton"
        :to="{ name: 'paymentSchedule' }"
        secondary
      >
        {{ $t('loanInformation.paymentSchedule') }}
      </brand-button>
    </div>
    <application-renewal-banner class="applicationrenewalBanner" />
    <div class="summaryInfo">
      <div class="header">
        {{ $t(`loanInformation.detailsHeader.${productType}`) }}
      </div>
      <div class="balance">{{ loanOutstandingBalance | money }}</div>
      <div>{{ $t('loanInformation.outstandingBalance') }}</div>
      <loan-progress-bar
        :percent="accountState === WITHDRAWN ? 0 : percentPaidDown"
        :part="principalPaid"
        :total="loanAmount"
      />
      <table class="table">
        <tbody class="body">
          <tr>
            <td>{{ $t('loanInformation.totalLoanAmountLabel') }}</td>
            <td>{{ loanAmount | money }}</td>
          </tr>
          <tr v-if="payoffAmount">
            <td>{{ $t('loanInformation.payOffAmountLabel') }}</td>
            <td>{{ payoffAmount | money }}</td>
          </tr>
          <tr v-if="disbursementAmount">
            <td>{{ $t('loanInformation.disbursementAmountLabel') }}</td>
            <td>{{ disbursementAmount | money }}</td>
          </tr>
          <tr>
            <td>
              {{
                $t('loanInformation.scheduledPaymentAmountLabel', {
                  scheduled: $t(`loanInformation.scheduled.${scheduled}`),
                })
              }}
            </td>
            <td>{{ nextInstallmentAmount | money }}</td>
          </tr>
          <tr>
            <td>
              {{ $t('loanInformation.remainingPayments') }}
            </td>
            <td>{{ remainingPayments }}</td>
          </tr>
          <tr>
            <td>
              {{ $t('loanInformation.loanTypeLabel') }}
            </td>
            <td>{{ $t(`products.${productType}`) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="footerLabel">
      {{ $t('bannerVerbiageLine1') }}
    </p>
    <p class="footerLabel">
      {{ $t('bannerVerbiageLine2', { partnerPhone: $t(partnerPhone) }) }}
      <brand-link :href="`mailto:${partnerEmail}`" class="link email">
        {{ partnerEmail }}
      </brand-link>
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { accountStates } from '@/modules/stateResolver';
import dateFormatsMixin from '@/mixins/dateFormatsMixin';
import LoanProgressBar from '@/components/LoanProgressBar';
import LoanIdentifier from '@/components/LoanIdentifier';
import ApplicationRenewalBanner from '@/views/Banners/ApplicationRenewalBanner';

export default {
  name: 'loan-information',
  components: {
    LoanProgressBar,
    LoanIdentifier,
    ApplicationRenewalBanner,
  },
  data() {
    return {
      WITHDRAWN: accountStates.WITHDRAWN,
      showPaymentScheduleButton: this.$env.VUE_APP_SHOW_PAYMENT_SCHEDULE_BUTTON,
      partnerPhone: this.$env.VUE_APP_PARTNER_PHONE,
      partnerEmail: this.$env.VUE_APP_PARTNER_EMAIL,
    };
  },
  computed: {
    ...mapGetters([
      'isOneTimePaymentsAllowed',
      'isPaymentScheduleAllowed',
      'accountState',
      'productType',
    ]),
    ...mapState('loan', {
      scheduled: 'periodUnit',
      loanAmount: 'amount',
      payoffAmount: 'competitorTotalPayoffAmount',
      disbursementAmount: 'splitDisbursementAmount',
    }),
    ...mapState('loanBalance', {
      loanOutstandingBalance: 'outstandingBalance',
      scheduledPayment: 'nextInstallmentAmount',
      remainingPayments: 'remainingInstallments',
      nextInstallmentAmount: 'nextInstallmentAmount',
      percentPaidDown: 'percentPaidDown',
      principalPaid: 'principalPaid',
    }),
    ...mapGetters('paymentSchedule', ['nextPaymentDate', 'nextPaymentDateAfterDate']),
    ...mapGetters('holdHistory', ['isBankAccountHold', 'endDateOfBankAccountHold']),
  },
  mixins: [dateFormatsMixin],
};
</script>

<style scoped lang="sass">
.applicationrenewalBanner
  margin: baseunit(1.3) 0

.loanInformation
  width: 100%
  height: 100%

  .summaryInfo
    padding: baseunit(2.4)
    border-radius: 4px
    border: 1px solid $glass-color-system-neutral-grey-04
    background-color: $glass-color-system-neutral-white
    color: $glass-color-system-neutral-grey-08
    +font-size(12,16)
    font-family: $fonts-public-sans-semibold
    margin-top: baseunit(1.2)

    .header
      font-family: $fonts-public-sans-bold
      +font-size(20,28)
      color: $glass-color-system-neutral-grey-09

    .balance
      +font-size(24,28)
      font-family: $fonts-public-sans-regular
      color: $glass-color-system-pop-green-dark
      margin-top: baseunit(1.2)

    .loanProgressBar
      margin-top: baseunit(2)

    .table
      border-collapse: collapse
      margin-top: baseunit(2.8)
      width: 100%
      color: $glass-color-system-neutral-grey-08
      font-family: $fonts-public-sans-semibold
      +font-size(12,16)

      tbody
        width: 100%

        tr
          width: 100%
          border-bottom: 1px solid $glass-color-system-neutral-grey-06

          &:last-child
            border-bottom: none

          td
            padding: baseunit(1.6) 0

          td:last-child
            color: $glass-color-system-neutral-grey-09
            font-family: $fonts-public-sans-regular
            +font-size(14,18)
            text-align: right

  .footerLabel
    font-style: italic
    font-weight: 100
    color: $color-abbey
    +font-size(12,16)

.buttons
  font-family: $fonts-public-sans-regular
  +font-size(16, 18)
  display: flex
  align-items: center

  .brandButton
    height: baseunit(3.2)
    margin-right: baseunit(0.4)
</style>
