import curry from 'lodash.curry';
import errorCodes from '@/modules/document-service/error-codes';

const ONE_MBYTE = 1024 * 1024;
const ACCEPTED_FILE_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];
const ACCEPTED_FILE_SIZE = 25;

const fileRuleArity = 2;

const ruleBuilder = (messagePath, condition) => (file, ...params) => {
  const fieldValid = condition(file, ...params);

  return {
    valid: fieldValid,
    msg: !fieldValid && { msg: messagePath, value: params },
  };
};

const conditions = {
  fileType: (file, param) => param.includes(file.type),
  maxFileSize: (file, maxSize) => maxSize * ONE_MBYTE >= file.size,
};

export const fileFormat = curry(
  ruleBuilder(errorCodes.WRONG_MIME_TYPE, conditions.fileType),
  fileRuleArity,
)(curry.placeholder, ACCEPTED_FILE_TYPES);

export const maxFileSize = curry(
  ruleBuilder(errorCodes.WRONG_SIZE, conditions.maxFileSize),
  fileRuleArity,
)(curry.placeholder, ACCEPTED_FILE_SIZE);
