import api from '@/modules/api-service';
import { notificationSubjects, postFundingStatuses } from '@/constants/notification';

// TODO when backend will be ready, or never
const ONLY_LOCALSTORAGE = true;

const notificationTypes = {
  SYSTEM: 'SYSTEM',
  TRANSACTION: 'TRANSACTION',
};

const notificationLevels = {
  ALERT: 'ALERT',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
};

const notificationMessageTypes = {
  CUSTOM: 'CUSTOM',
  LOCALIZED: 'LOCALIZED',
};

const LOCAL_STORAGE_KEY = 'customer_portal';

const getAcknowledgedNotifications = () => {
  try {
    return (
      JSON.parse(window.localStorage.getItem(`${LOCAL_STORAGE_KEY}_acknowledgedNotifications`)) ||
      []
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return [];
};

const setAcknowledgedNotifications = value => {
  window.localStorage.setItem(
    `${LOCAL_STORAGE_KEY}_acknowledgedNotifications`,
    JSON.stringify(value),
  );
};

let idCounter = 0;

const nextId = () => {
  idCounter += 1;

  return idCounter;
};

const initialModuleState = {
  acknowledgedNotifications: getAcknowledgedNotifications(),
  notifications: [],
  subjects: notificationSubjects,
  levels: notificationLevels,
  messageTypes: notificationMessageTypes,
};

export default {
  namespaced: true,
  state: { ...initialModuleState },
  getters: {
    renewalNotifications({ notifications, subjects }) {
      return notifications.filter(({ subject }) => subject === subjects.APPLICATION_RENEWAL);
    },
    bannersByLevel: ({ notifications, subjects }) => filteredLevel =>
      notifications.filter(
        ({ acknowledged, level, subject, message }) =>
          !acknowledged &&
          level === filteredLevel &&
          ![subjects.WELCOME, subjects.APPLICATION_RENEWAL].includes(subject) &&
          !(
            subject === notificationSubjects.POST_FUNDING &&
            message &&
            message.postFundingStatus === postFundingStatuses.PASS
          ),
      ),
    hasWelcomeBanner: ({ notifications }) => {
      const welcome = notifications.some(
        ({ subject, acknowledged }) => !acknowledged && subject === notificationSubjects.WELCOME,
      );
      const postFundingPass = notifications.some(
        ({ subject, message }) =>
          subject === notificationSubjects.POST_FUNDING &&
          message &&
          message.postFundingStatus === postFundingStatuses.PASS,
      );
      const postFundingNotPass = notifications.some(
        ({ subject, message }) =>
          subject === notificationSubjects.POST_FUNDING &&
          message &&
          [postFundingStatuses.FAIL, postFundingStatuses.PENDING].includes(
            message.postFundingStatus,
          ),
      );

      return (welcome && !postFundingNotPass) || postFundingPass;
    },
    welcomeBanner: ({ notifications }) =>
      notifications.find(
        notice =>
          notice.subject === notificationSubjects.WELCOME ||
          (notice.subject === notificationSubjects.POST_FUNDING &&
            notice.message.postFundingStatus === postFundingStatuses.PASS),
      ),
  },
  actions: {
    getNotifications({ commit, rootGetters }) {
      return api.getNotifications(rootGetters.loanId).then(response => {
        commit('updateNotifications', response.data);
      });
    },
    closeBanner({ state, commit, rootGetters }, notificationId) {
      const notification = state.notifications.find(({ id }) => id === notificationId);

      if (notification) {
        if (notification.notificationType === notificationTypes.SYSTEM || ONLY_LOCALSTORAGE) {
          const acknowledgedNotifications = getAcknowledgedNotifications();

          acknowledgedNotifications.push(notification.id);
          setAcknowledgedNotifications(acknowledgedNotifications);
          commit('updateAcknowledgedNotifications', acknowledgedNotifications);
          commit('closeBanner', notification.id);
        } else if (notification.notificationType === notificationTypes.TRANSACTION) {
          api
            .updateNotification(rootGetters.loanId, notification.id, {
              ...notification,
              acknowledged: true,
            })
            .then(() => {
              commit('closeBanner', notification.id);
            });
        }
      }
    },
  },
  mutations: {
    updateAcknowledgedNotifications(state, acknowledgedNotifications) {
      state.acknowledgedNotifications = acknowledgedNotifications;
    },
    updateNotifications(state, notifications) {
      if (Array.isArray(notifications)) {
        state.notifications = notifications.map(notification => ({
          ...notification,
          acknowledged:
            notification.acknowledged ||
            state.acknowledgedNotifications.indexOf(notification.id) !== -1,
        }));
      } else {
        state.notifications = [];
      }
    },
    closeBanner(state, notificationId) {
      const index = state.notifications.findIndex(({ id }) => id === notificationId);

      if (index !== -1) {
        const notification = state.notifications[index];

        state.notifications.splice(index, 1, { ...notification, acknowledged: true });
      }
    },
    createDocSubmitNotification(state) {
      const notification = {
        id: nextId(),
        subject: notificationSubjects.DOC_SUBMIT_SUCCESS,
        level: notificationLevels.SUCCESS,
        notificationType: notificationTypes.SYSTEM,
        sticky: false,
        messageType: notificationMessageTypes.LOCALIZED,
      };

      state.notifications.push(notification);
    },
    createPaymentSubmittedNotification(state) {
      const notification = {
        id: nextId(),
        subject: notificationSubjects.PAYMENT_SUBMITTED,
        level: notificationLevels.INFO,
        notificationType: notificationTypes.SYSTEM,
        sticky: false,
        messageType: notificationMessageTypes.LOCALIZED,
      };

      state.notifications.push(notification);
    },
    createRevokeElectronicConsentNotification(state) {
      const notification = {
        id: nextId(),
        subject: notificationSubjects.REVOKE_ELECTRONIC_CONSENT,
        level: notificationLevels.INFO,
        notificationType: notificationTypes.SYSTEM,
        sticky: false,
        messageType: notificationMessageTypes.LOCALIZED,
      };

      state.notifications.push(notification);
    },
    createBankAccountChangeNotification(state) {
      const notification = {
        id: nextId(),
        subject: notificationSubjects.BANK_ACCOUNT_CHANGE,
        level: notificationLevels.INFO,
        notificationType: notificationTypes.SYSTEM,
        sticky: false,
        messageType: notificationMessageTypes.LOCALIZED,
      };

      state.notifications.push(notification);
    },
  },
};
