<template>
  <div class="paymentSchedule" v-if="initialized" key="paymentSchedule">
    <loan-identifier />
    <loan-payment-progress />
    <div class="paymentScheduleBlock" v-if="showStandardPaymentActivity">
      <div class="header">
        <heading level="4">
          {{ $t('paymentSchedule.title') }}
        </heading>
        <brand-link @click="getScheduleCSV" class="download" v-if="showDownloadStandardPaymentLink">
          <svg-use name="component _ utility _ system _ action-download" width="16" height="16" />
          {{ $t('paymentSchedule.downloadLink') }}
        </brand-link>
      </div>
      <div class="note">
        {{ $t('paymentSchedule.note') }}
      </div>
      <div class="statusSelector">
        <div class="label">{{ $t('paymentSchedule.statusSelectorLabel') }}</div>
        <input-select
          id="show-by-time"
          :value="selectedStatus"
          @change="selectedStatus = $event"
          :options="statusOptions"
        />
      </div>

      <payment-schedule-table
        :rows="filteredPayments"
        :total="calculatedTotal"
        class="scheduleTable"
      />
    </div>
  </div>
  <centred-spinner v-else key="spinner" />
</template>
<script>
import { mapState, mapActions } from 'vuex';
import paymentScheduleGuard from '@/mixins/paymentScheduleGuard';
import CentredSpinner from '@/components/CentredSpinner';
import LoanIdentifier from '@/components/LoanIdentifier';
import LoanPaymentProgress from '@/components/LoanPaymentProgress';
import PaymentScheduleTable from '@/components/PaymentScheduleTable';
import getDataBeforeRenderMixin from '@/mixins/getDataBeforeRenderMixin';
import scheduleStatuses, {
  ALL,
  mapPaymentStatusToPropertySuffix,
} from '@/modules/schedule-statuses';

export default {
  name: 'payment-schedule',
  components: {
    PaymentScheduleTable,
    CentredSpinner,
    LoanPaymentProgress,
    LoanIdentifier,
  },
  data() {
    return {
      selectedStatus: ALL,
      showStandardPaymentActivity: this.$env.VUE_APP_SHOW_STANDARD_PAYMENT_ACTIVITY,
      showDownloadStandardPaymentLink: this.$env
        .VUE_APP_SHOW_DOWNLOAD_STANDARD_PAYMENT_ACTIVITY_LINK,
    };
  },
  mixins: [getDataBeforeRenderMixin, paymentScheduleGuard],
  computed: {
    ...mapState('paymentSchedule', ['payments']),
    statusOptions() {
      return scheduleStatuses.map(type => ({
        key: type,
        value: this.$t(`paymentSchedule.paymentStatus.${type}`),
      }));
    },
    filteredPayments() {
      return this.payments
        .filter(({ status }) => this.selectedStatus === ALL || this.selectedStatus === status)
        .map((payment, index) => ({
          id: index,
          principle: this.getValueByName(payment, 'principal'),
          interest: this.getValueByName(payment, 'interest'),
          fee: this.getValueByName(payment, 'fee'),
          total: this.getValueByName(payment, 'total'),
          ...payment,
        }));
    },
    calculatedTotal() {
      const initialValue = {
        principle: 0,
        interest: 0,
        fee: 0,
        total: 0,
      };

      const calculateTotal = (acc, currentValue) => {
        Object.keys(initialValue).forEach(property => {
          acc[property] += currentValue[property];
        });
        return acc;
      };

      return this.filteredPayments.reduce(calculateTotal, initialValue);
    },
  },
  methods: {
    ...mapActions('paymentSchedule', ['getSchedule', 'getScheduleCSV']),
    getValueByName({ status, ...payment }, propertyName) {
      const suffix = mapPaymentStatusToPropertySuffix[status];
      const propertyNameWithSuffix = `${propertyName}${suffix}`;

      return payment[propertyNameWithSuffix];
    },
    async getDataBeforeRender() {
      await this.getSchedule();
    },
  },
};
</script>
<style lang="sass" scoped>
.loanPaymentProgress
  margin-top: baseunit(2.8)

.paymentScheduleBlock
  background-color: $glass-color-system-neutral-white
  padding: baseunit(2.4) 0 0
  border-radius: 4px
  border: 1px solid $glass-color-system-neutral-grey-04
  margin-top: baseunit(1.6)
  font-family: $fonts-public-sans-regular
  color: $glass-color-system-neutral-grey-09

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin: 0 baseunit(2.4)

  .title
    font-family: $font-family-galano-grotesque
    +font-size(20)
    line-height: 1.4
    color: $color-abbey

  .download
    display: flex
    +font-size(14, 16)

    svg
      margin-right: baseunit(0.4)

.note
  margin: 0 baseunit(2.4)
  +font-size(12, 18)

.statusSelector
  display: flex
  align-items: center
  margin: baseunit(2.4)  baseunit(2.4) 0

  .label
    +font-size(16,20)
    color: $glass-color-system-neutral-grey-09
    margin-right: baseunit(0.8)
    margin-left: auto

  .inputSelect
    width: 100%
    max-width: baseunit(21)

.scheduleTable
  margin-top: baseunit(2.4)
</style>
