<template>
  <div class="formControl">
    <date-input
      id="paymentDate"
      class="date"
      :format="dateFormat"
      :placeholder="datePlaceholder"
      :value="paymentDate"
      :open-date="partnerToday"
      maximum-view="day"
      :disabled-dates="disabledDates"
      @selected="onSelected"
      :error-state="!isDateValid"
      :disabled="isPaidInFull"
    >
      <div class="label" slot="label">
        <div>{{ $t('payment.details.dateInputLabel') }}</div>
        <div class="inputSublabel">
          {{
            $t('payment.details.dateInputSublabel', {
              paymentSupportNumber,
            })
          }}
        </div>
      </div>
    </date-input>
    <div class="validationError">
      <one-time-payment-error
        v-if="!isDateValid"
        :message="$t(`payment.details.error.${invalidDateText}`)"
      >
      </one-time-payment-error>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import { EMPTY_DATE, PAID_IN_FULL } from '@/validation/error-types';
import DateInput from '@/components/DateInput';
import OneTimePaymentError from '@/components/OneTimePayment/Error';
import dateUtils from '@/utils/dateUtils';
import ruleTypesMixin from '@/mixins/ruleTypesMixin';
import validationMixin from '@/mixins/validationMixin';

export default {
  name: 'one-time-payment-date',
  components: {
    DateInput,
    OneTimePaymentError,
  },
  mixins: [ruleTypesMixin, validationMixin],
  props: {
    isPaidInFull: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      partnerTimezone: this.$env.VUE_APP_PARTNER_TIMEZONE,
      INPUT_DATE: 'paymentDate',
      paymentSupportNumber: this.$env.VUE_APP_PAYMENT_SUPPORT_PHONE,
    };
  },

  computed: {
    ...mapState('oneTimePayment', { paymentDate: 'scheduleDate' }),
    ...mapState('bankHolidays', ['holidays']),
    datePlaceholder() {
      return this.$t('dateFormats.dateInputPlaceholder');
    },
    payoffDay() {
      return dateUtils.convertStringToDate(this.payoffDate);
    },
    partnerToday() {
      return DateTime.local()
        .setZone(this.partnerTimezone)
        .startOf('day')
        .setZone('local', { keepLocalTime: true })
        .toJSDate();
    },
    disabledDates() {
      const holidays = this.holidays.map(({ date }) => dateUtils.convertStringToDate(date));
      const weekends = [0, 6];

      return {
        from: this.payoffDay,
        to: this.partnerToday,
        dates: holidays,
        days: weekends,
      };
    },
    isDateValid() {
      return !this.errors.find(({ field }) => field === this.INPUT_DATE);
    },
    invalidDateText() {
      return this.getErrorMessageFor(this.INPUT_DATE);
    },
  },
  methods: {
    onSelected(value) {
      this.$emit('isValid', this.isDateValid);
      this.$emit('updateValue', value);
      this.validation();
    },
    getErrorMessageFor(field) {
      return this.errors?.find(({ field: invalidField }) => invalidField === field)?.errorMessage;
    },
    dateFormat() {
      return this.paymentDate
        ? this.$options.filters.date(this.paymentDate, this.DATE_INPUT)
        : this.$t('dateFormats.datePlaceholder');
    },
    rules() {
      return [
        {
          field: this.INPUT_DATE,
          rule: this.ruleTypes.IS_EMPTY,
          errorMessage: EMPTY_DATE,
        },
        ...(this.isPaidInFull
          ? [
              {
                field: this.INPUT_DATE,
                rule: this.ruleTypes.IS_DISABLED,
                disabledDates: this.disabledDates,
                errorMessage: PAID_IN_FULL,
              },
            ]
          : []),
      ];
    },
  },
};
</script>
<style lang="sass" scoped>
@import '@/components/OneTimePayment/oneTimePayment'
.inputSublabel
  +font-size(12)
  color: $glass-color-system-neutral-grey-08
</style>
