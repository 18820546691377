<script>
import BrandLink from '@/components/BrandLink';
/* import buildTranslators from '@/utils/build-translators'; */
/**
 * Just footer with phone and email links.
 */
export default {
  name: 'brand-footer',
  data() {
    return {
      partnerPrivacyPolicy: this.$env.VUE_APP_PARTNER_PRIVACY_POLICY,
      partnerPrivacyStatement: this.$env.VUE_APP_PARTNER_PRIVACY_STATEMENT,
      termsAndConditions: this.$env.VUE_APP_PARTNER_TERMS_AND_CONDITIONS,
    };
  },
  components: { BrandLink },
  props: {
    partnerPhone: {
      type: String,
      required: true,
    },
    partnerEmail: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <footer>
    <div class="sidebar">
      <div class="section leftSide">
        <slot name="partner-logo" />
        <div class="description">
          <span v-text="$t('brandFooter.leftSide.description1')" />
          <span>
            <brand-link class="policyLink" :href="partnerPrivacyPolicy">
              {{ $t('privacyPolicyLink') }} </brand-link
            >,
            <brand-link class="policyLink" :href="partnerPrivacyStatement">
              {{ $t('privacyStatementLink') }}
            </brand-link>
            and
            <brand-link class="policyLink" :href="termsAndConditions">
              {{ $t('termsConditionsLink') }} </brand-link
            >.
          </span>
          <span v-text="$t('brandFooter.leftSide.description2')" />
        </div>
      </div>
      <div class="section rightSide">
        <h3 class="header">{{ $t('questionsLabel') }}</h3>
        <div class="subtitle">{{ $t('brandFooter.rightSide.subtitle') }}</div>
        <div class="workHours">
          <div>
            {{ $t('brandFooter.rightSide.workHours.weekdays.days') }}
          </div>
          <div>
            {{ $t('brandFooter.rightSide.workHours.weekdays.hours') }}
          </div>
          <div>
            {{ $t('brandFooter.rightSide.workHours.weekend.days') }}
          </div>
          <div>
            {{ $t('brandFooter.rightSide.workHours.weekend.hours') }}
          </div>
        </div>
        <div class="contacts">
          <div class="contact">
            <div class="label">
              {{ $t('callLabel') }}
            </div>
            <brand-link :href="`tel:${partnerPhone}`" class="link phone">
              {{ partnerPhone }}
            </brand-link>
          </div>
          <div class="contact">
            <div class="label">
              {{ $t('emailLabel') }}
            </div>
            <brand-link :href="`mailto:${partnerEmail}`" class="link email">
              {{ partnerEmail }}
            </brand-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="sass" scoped>
$brand-footer-text-font: (font-size: 12, line-height: 18) !default
$brand-footer-label-font: (font-size: 12, line-height: 16) !default
$brand-footer-link-font: (font-size: 14, line-height: 18) !default
$brand-footer-background-color: $glass-color-brand-primary-dark-full !default

footer
  background-color: $brand-footer-background-color
  display: flex
  justify-content: center
  padding: baseunit(2) baseunit(2.4)
  color: $glass-color-system-neutral-white

.sidebar
  width: 100%
  letter-spacing: normal
  font-style: normal
  flex-direction: column
  font-stretch: normal
  line-height: 1.33
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: nowrap
  text-align: center

  .section.leftSide
    .description
      margin-top: baseunit(2)
      +font-size($brand-footer-text-font...)
      font-family: $fonts-public-sans-regular
      max-width: baseunit(68.4)

    .footnote
      margin-top: baseunit(2.4)

  +mq($tyranno)
    text-align: left
    flex-direction: row
    padding: 0

    .section
      &.leftSide
        flex: 2 2 50%
        padding-right: $glass-spacing-3
        border-right: 1px solid $color-system-neutral-gray-3
      &.rightSide
        flex: 1 1 50%
        margin-left: baseunit(5.6)

.rightSide
  display: flex
  flex-direction: column

  .header
    +font-size(20,28)
    margin: 0

  .subtitle
    +font-size(12,18)
    margin-top: baseunit(1.2)

  .workHours
    +font-size(12,18)
    font-family: $fonts-public-sans-regular
    margin-top: baseunit(1.2)
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    column-gap: baseunit(0.8)
    max-width: baseunit(22)

  .contacts
    display: flex
    margin-top: baseunit(1.6)

  .contact
    .label
      +font-size($brand-footer-label-font...)
      font-family: $fonts-public-sans-semibold
      margin-bottom: $glass-spacing-025

    .link
      +font-size($brand-footer-link-font...)
      font-weight: bold

  .contact + .contact
    margin-left: $glass-spacing-4

.link
  color: $glass-color-system-neutral-white

.linkContainer
  .brandLink
    +font-size(14, 20)
    font-family: $fonts-public-sans-bold
    margin-right: baseunit(2.4)

.policyLink
  text-decoration: underline
  cursor: pointer
  color: $glass-color-system-neutral-white
</style>
