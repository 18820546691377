<template>
  <div>
    <application-banner />
    <div v-for="level in levels" :key="level">
      <simple-banner
        v-for="(item, index) in bannersByLevel(level)"
        :key="index"
        :id="item.id"
        :message="message(item)"
        :class="level.toLowerCase()"
        :closeable="!item.sticky"
        :subject="item.subject"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import get from 'lodash.get';
import SimpleBanner from '@/components/SimpleBanner';
import ApplicationBanner from '@/components/ApplicationBanner';
import { notificationSubjects } from '@/constants/notification';

export default {
  name: 'banner-box',
  components: {
    ApplicationBanner,
    SimpleBanner,
  },
  data() {
    return {
      partnerPhone: this.$env.VUE_APP_PARTNER_PHONE,
      paymentSupportPhone: this.$env.VUE_APP_PAYMENT_SUPPORT_PHONE,
    };
  },
  computed: {
    ...mapState('notification', ['levels', 'messageTypes']),
    ...mapGetters('notification', ['bannersByLevel']),
    ...mapState('loanBalance', {
      totalDue: 'totalDue',
    }),
  },
  methods: {
    message(notification) {
      if (!notification) return notification;

      const { messageType, subject, message } = notification;

      if (subject === notificationSubjects.POST_FUNDING) {
        return this.$t(`notifications.POST_FUNDING.${message.postFundingStatus}`, {
          phoneNumber: message.phoneNumber,
        });
      }

      if (messageType === this.messageTypes.LOCALIZED) {
        const amount = subject === notificationSubjects.PAST_DUE ? this.totalDue : null;
        return this.$t(`notifications.${subject}`, {
          partnerPhone: this.partnerPhone,
          paymentSupportPhone: this.paymentSupportPhone,
          ...(message &&
            message.additionalInfo && {
              pastDueAmount: this.moneyFilter(amount || message.additionalInfo.amount, 2),
              ...message.additionalInfo,
            }),
        });
      }

      return get(notification, 'message.body');
    },
  },
};
</script>
