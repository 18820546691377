export default {
  VUE_APP_SERVICE_NAME: String,

  VUE_APP_SHOW_DECIMAL_ZERO: Boolean,
  VUE_APP_LOAN_AMOUNT_ROUNDED: Boolean,

  VUE_APP_I18N_LOCALE: String,
  VUE_APP_I18N_FALLBACK_LOCALE: String,

  VUE_APP_PARTNER_PHONE: String,
  VUE_APP_PARTNER_EMAIL: String,
  VUE_APP_CUSTOMER_SERVICE_PHONE: String,
  VUE_APP_PAYMENT_SUPPORT_PHONE: String,
  VUE_APP_PARTNER_WORK_HOURS: String,
  VUE_APP_PARTNER_WORK_HOURS_SHORT: String,
  VUE_APP_PARTNER_PRIVACY_STATEMENT: String,
  VUE_APP_PARTNER_TERMS_AND_CONDITIONS: String,
  VUE_APP_PARTNER_PRIVACY_POLICY: String,
  VUE_APP_PARTNER_TIMEZONE: String,

  VUE_APP_API_BASE_URL: String,

  VUE_APP_API_BASE_PATH: String,
  VUE_APP_ENABLE_TRACING: Boolean,

  VUE_APP_IDP_PROVIDER_DOMAIN: String,
  VUE_APP_IDP_PROVIDER_CLIENT_ID: String,
  VUE_APP_IDP_PROVIDER_CONNECTION_NAME: String,
  VUE_APP_IDP_PROVIDER_AUDIENCE: String,
  VUE_APP_API_BASIC_INFO_URL: String,

  VUE_APP_ENABLE_RUM: Boolean,
  VUE_APP_RUM_SERVICE_NAME: String,
  VUE_APP_APPLICATION_UI_LOGIN_LINK: String,
  VUE_APP_RUM_SERVER_URL: String,
  VUE_APP_RUM_SERVICE_VERSION: String,
  VUE_APP_RUM_ENVIRONMENT: String,

  VUE_APP_ENABLE_IDLE_MODAL: Boolean,
  VUE_APP_IDLE_MODAL_SHOW_TIMEOUT: Number,
  VUE_APP_IDLE_MODAL_WAIT_TIMEOUT: Number,
  VUE_APP_IDLE_MODAL_UPDATE_MONITORING_INTERVAL: Number,
  VUE_APP_IDLE_MODAL_USER_ACTIVITY_STORAGE_NAME: String,

  VUE_APP_PARTNER_FAQ_LINK: String,
  VUE_APP_SUPPORTED_BROWSERS: String,

  VUE_APP_BANK_PARTNER_NAME: String,

  VUE_APP_SHOW_TRANSACTION_HISTORY: Boolean,
  VUE_APP_SHOW_DOWNLOAD_TRANSACTION_HISTORY_LINK: Boolean,
  VUE_APP_SHOW_STANDARD_PAYMENT_ACTIVITY: Boolean,
  VUE_APP_SHOW_DOWNLOAD_STANDARD_PAYMENT_ACTIVITY_LINK: Boolean,
  VUE_APP_SHOW_PAYMENT_SCHEDULE_BUTTON: Boolean,
  VUE_APP_SHOW_PAYMENT_SCHEDULE_DROPDOWN: Boolean,
};
